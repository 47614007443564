@import "src/styles/bootstrap5/functions";
@import "src/styles/bootstrap5/variables";

.inputGroup {
    margin-bottom: .6em;

    .labelContainer {
        margin-bottom: .3em;
        position: relative;

        .hintInfo {
            display: flex;
            justify-content: center;
            align-items: center;
            display: inline-block;
            position: relative;

            .hintBubble {
                position: absolute;
                margin-top: 0.8em;
                z-index: 10000;
                width: 100%;
                right: 0;

                .hintArrow {
                    position: absolute;
                    left: -5px;
                    width: 0;
                    height: 0;
                    bottom: 100%;
                    border: .75em solid transparent;
                    border-top: none;
                    border-bottom-color: #fff;
                    filter: drop-shadow(0 -0.0625em 0.0625em rgba(0, 0, 0, .1));
                    z-index: 1;
                }

                .hintContent {
                    position: absolute;
                    top: -2px;
                    font-size: .9em;
                    width: max-content;
                    max-width: 20em;
                    padding: 1em 1.2em;
                    display: inline-block;
                    background-color: #fff;
                    border-radius: 1em;
                    box-shadow:	0 0.125em 0.5em rgba(0, 0, 0, .3), 0 0.0625em 0.125em rgba(0, 0, 0, .2);
                }
            }
        }

        &.hasAdditionalLabel {
            display: flex;
            align-items: baseline;

        }
    }

    input[type=number], input[type=text], select {
        width: 100%;
        padding: .5em;
        border: none;
        -webkit-appearance: none;
        appearance: none;
        box-shadow: 0 0 3px $gray-400;
        border-radius: 5px;
        outline: none !important;
    
        &:focus {
            box-shadow: 0 0 3px $gray-500;
        }
    }
    
    .inputContainer {
        position: relative;

        .innerSwitcher {
            position: absolute;
            display: flex;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            font-size: .8rem;

            label {
                padding: .1rem .3rem;
                cursor: pointer;
                background: $gray-200;
                text-align: center;
                border: 1px solid transparent;
                user-select: none;

                &:first-child {
                    border-radius: 4px 0 0 4px;
                }

                &:last-child {
                    border-radius: 0 4px 4px 0;
                }

                &:hover {
                    background: whitesmoke;
                }
                &.checked {
                    background: $gray-300;
                    color: var(--darkest-primary);
                    border: 1px solid $gray-400;
                }
                @media (max-width: 480px) {
                    &.checked {
                        display: none;
                    }
                    &:not(.checked) {
                        border-radius: 4px;
                    }
                }
    
            }

            input[type=radio] {
                width: 0;
                height: 0;
                display: inline-block;
            }
        }
    }

    &.oneLine {
        display: flex;
        vertical-align: middle;
        align-items: center;
        .labelContainer {
            margin-bottom: 0;
        }
        .inputContainer {
            position: relative;
            margin: 0;
            > div {
                margin-bottom: 0;
            }
        }
        input[type=number], input[type=text], select {
            width: auto;
        }
    }
}

.infoIcon {
    display: inline-block;
    cursor: pointer;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    background: linear-gradient(to bottom, #f1f1f1, #e7e7e7);
    text-align: center;
    -webkit-appearance: none;
    appearance: none;
    box-shadow: 0 0 3px var(--darkest-primary);
    color: var(--darkest-primary);
    position: relative;
    font-family: initial;
    font-size: 1em;
    line-height: 1em;
    font-style: italic;

    &:before {
        content: "i";
    }

    &:hover {
        background: linear-gradient(to bottom, #f7f7f7, #f1f1f1);
        box-shadow: 0 0 4px var(--darkest-primary);
    }
}
