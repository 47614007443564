@import "src/styles/bootstrap5/functions";
@import "src/styles/bootstrap5/variables";

.menuToggle {
  display: block;
  position: relative;

  z-index: 1;

  -webkit-user-select: none;
  user-select: none;

  a {
    text-decoration: none;
    color: #232323;

    transition: color 0.3s ease;

    &:hover {
      color: var(--primary);
    }
  }

  > input {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    left: -5px;

    cursor: pointer;

    opacity: 0;
    /* hide this */
    z-index: 2;
    /* and place it over the hamburger */

    -webkit-touch-callout: none;

    &:checked {
      &~ul, &~div.menu {
        transform: translate(-50%, -50%);
        opacity: 1;
      }
    }
  }

  span {
    display: block;
    width: 33px;
    height: 4px;
    margin: 5px 0;
    position: relative;
  
    background: #cdcdcd;
    border-radius: 3px;
  
    z-index: 1;
  
    transform-origin: 4px 0px;
  
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
      opacity 0.55s ease;

    &:first-child {
      transform-origin: 0% 0%;
    }
    
    &:nth-last-child(3) {
      transform-origin: 0% 100%;
    }
    
  }

  > input:checked~span {
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);
    background: #232323;

    &.midSpan {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }

    &:nth-last-child(3) {
      transform: rotate(-45deg) translate(0, -1px);
    }
  }

  > input {
    &~.overlay {
      position: fixed;
      background: rgba(0, 0, 0, 0.3);
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;
      opacity: 0;
      transition: .5s;
      visibility: hidden;
    }
    &:checked~.overlay {
      opacity: 1;
      visibility: visible;
    } 
  }

  .menu {
    position: fixed;
    width: 300px;
    margin: auto auto;
    padding: 40px;
    top: 50%;
    left: 50%;
    background: white;
    list-style-type: none;
    box-shadow: 0 0 4px silver;
    border-radius: 4px;
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    transform: translate(-2000px, -50%);
    opacity: 0;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity .5s ease-out;

    li {
      padding: 10px 0;
      font-size: 18px;
    }
  }

  div.menu {
    background: white;
    padding: 10px;
  }
}
