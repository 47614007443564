
.form-group {
    margin-bottom: .7em;
}

.form-control {
    width: 100%;
    padding: .5em;
    border: none;
    -webkit-appearance: none;
    box-shadow: 0 0 3px $gray-400;
    border-radius: 5px;
    outline: none !important;

    &:focus {
        box-shadow: 0 0 3px $gray-500;
    }
}

.form-label {
    margin-bottom: .3em;
}

.btn {
    margin: 0;
    padding: .5rem .75rem;
    font-size: inherit;
    background: transparent;
    border: none;
    box-shadow: 0 0 0 1px $gray-500;
    border-radius: 4px;

    &.btn-block { 
        display: block;
        width: 100%;
    }
}

.btn-primary {
    box-shadow: 0 0 0 1px var(--primary);
    color: var(--primary);

    &:hover {
        box-shadow: 0 0 0 1px var(--lighten-primary);
        color: var(--lighten-primary);
    }

    &:focus, &:active {
        box-shadow: 0 0 0 1px var(--darken-primary);
        color: var(--darken-primary);
    }
}


.btn-round-sm {
    padding: .1rem;
    box-shadow: 0 0 0 1px var(--primary);

    .toggle-icon {
        margin: 0;
        padding: 0;
        color: var(--primary);
    }
}

