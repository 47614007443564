@import "src/styles/bootstrap5/functions";
@import "src/styles/bootstrap5/variables";

.selectBox {
    position: relative;

    > .selected {
        padding: .5em;
        -webkit-appearance: none;
        box-shadow: 0 0 3px $gray-400;
        border-radius: 5px;
        cursor: pointer;
        user-select: none;
        position: relative;
    
    
        &:before {
            content: "▾";
            position: absolute;
            right: .6em;
            top: 50%;
            transform: translateY(-50%) rotate(0deg);
            transition: all .2s;
        }    
    }

    .overlay {
        z-index: 99999;
        position: fixed;
        width: 100vw;
        height: 100vh;
        display: inline-block;
        background: rgba(0,0,0,0.03);
        left: 0;
        top: 0;
    }

    .items {
        position: absolute;
        max-height: 0;
        overflow: hidden;
        transition: max-height .2s;
        background: white;
        padding: 0;
        -webkit-appearance: none;
        box-shadow: 0 0 3px $gray-400;
        border-radius: 0 0 5px 5px;
        width: 100%;
        z-index: 100000;
        direction: ltr;

        .searchBox {
            padding: 0;
            position: absolute;
            top: 0;
            height: 2.4em;
            width: 100%;
            
            input {
                padding: .5em;
                width: 100%;
                height: 100%;
                border-radius: 0;
                border: 0;
                border-bottom: 1px solid $gray-200;
                &:focus {
                    border: 0;
                    border-bottom: 1px solid $gray-300;
                }
            }
        }

        ul {
            padding: 0;
            list-style: none;
            user-select: none;
            margin: 0;
            max-height: 210px;
            overflow: auto;

            &.hasSearch {
                margin-top: 2.4em;
            }
    
            li {
                padding: .5em;
                cursor: pointer;
                background-color: $white;

                .longnote {
                    font-size: 0.7em;
                    color: var(--darkest-primary);
                }

                span {
                    margin-left: .6em;
                    color: $gray-600;
                }
    
                &:hover {
                    background-color: $gray-100;
                }
    
                &:active, &:focus {
                    background-color: $gray-400;
                }
    
                &.selected {
                    background-color: var(--lightest-primary);
                }
            }
        }    
    }

    &.open {

        > .selected {
            border-radius: 5px 5px 0 0;

            &:before {
                transform: translateY(-50%) rotate(180deg);
            }    
        }

        .items {
            max-height: 250px;
        }
    }

    &.rtl {
        > .selected {        
            &:before {
                left: .6em;
                right: unset;
            }    
        }
    }
}
