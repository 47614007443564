@import "src/styles/bootstrap5/functions";
@import "src/styles/bootstrap5/variables";

.noteContainer {
  max-height: 350px;
  overflow: auto;
  background: whitesmoke;
  text-align: left;
  padding: 20px;
  border: 1px solid $gray-400;
  border-radius: 4px;
}
