@import "src/styles/bootstrap5/functions";
@import "src/styles/bootstrap5/variables";

.fieldSwitcherContainer {
    margin-bottom: 1em;
    
    .fieldSwitcher {
        display: flex;
        justify-content: center;

        .switch {
            box-shadow: 0 0 0 1px $gray-300;
            color: $gray-600;
            border-radius: 4px;
            user-select: none;
            cursor: pointer;
            transition: all .2s;
            width: 100%;

            &:first-child {
                margin-right: .5em;
            }

            label {
                display: inline-block;
                padding: .7em .7em .7em 1.5em;
                cursor: pointer;
                position: relative;
                margin: 0;

                display: block;
                position: relative;
                cursor: pointer;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                font-size: .9em;
                line-height: 1.5em;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;                      
                input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;
                }
                  
                .checkmark {
                    position: absolute;
                    height: .9em;
                    width: .9em;
                    background-color: $gray-300;
                    left: .4em;
                    top: 50%;
                    transform: translateY(-50%);
                    margin: 0;
                    cursor: pointer;
                    transition: all .2s;

                    &:after {
                        content: "";
                        position: absolute;
                        display: none;
                    }
                }
                  
                &:hover input ~ .checkmark {
                    background-color: #ccc;
                }
                  
                input:checked ~ .checkmark {
                    background-color: var(--primary);
                }
                
                
                input:checked ~ .checkmark:after {
                    display: block;
                }
                  
                .checkmark:after {
                    left: .3em;
                    top: .2em;
                    width: .3em;
                    height: .4em;
                    border: solid white;
                    border-width: 0 1px 1px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }

            &:hover {
                box-shadow: 0 0 0 1px var(--primary);
                color: var(--primary);
            }
            
            &.selected {
                box-shadow: 0 0 0 1px var(--primary);
                color: var(--primary);
                &:hover {
                    box-shadow: 0 0 0 1px var(--darken-primary);
                    color: var(--darken-primary);
                }
            }
        }
    }

    &.rtl {
        .fieldSwitcher {
            .switch {
                &:first-child {
                    margin-right: 0;
                    margin-left: .5em;
                }

                label {
                    padding: .7em 1.5em .7em .7em;
                }
            }
        } 
    }

    &.noLine {
        .fieldSwitcher {
            .switch { 
                box-shadow: none !important;
            }
        }
    }
}