@import "src/styles/bootstrap5/functions";
@import "src/styles/bootstrap5/variables";

.adLinks {

    &.styled {
        margin-top: 1em;
    }

    a {
        user-select: none;
        -webkit-user-drag: none;
        text-decoration: underline;
        border: none;
        border-radius: 4px;
        display: block;
        text-align: center;
        min-height: 100%;

        &.styled {
            text-decoration: none;
            background: var(--primary);
            color: whitesmoke;
            padding: .3em .75em;
    
            &:hover{
                background: var(--lighten-primary);
            }
            &:focus, &:active {
                background: var(--darken-primary);
            }
        }
    }
}
