@import "src/styles/bootstrap5/functions";
@import "src/styles/bootstrap5/variables";

.results {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 1.2em;
    color: $gray-800;

    .resultItem {
        text-align: center;

        .resultTitle {
            color: $gray-600;
        }

        .resultValue {
            font-weight: bold;
        }
    }
    
    &.flexStart {
        justify-content: flex-start;
        align-items: flex-start;

        .resultItem {
            >div {
                margin: auto;
                margin-left: 2em;
            }
        }
    }

    &.vertical {
        justify-content: flex-start;
        align-items: flex-start;
        font-size: 1em;
        .resultItem {
            display: flex;
            flex-direction: row;
            >div {
                margin: auto;
                margin-left: 1em;
            }
        }
    }

}

@media (max-width: 380px) {
    .results {
        font-size: .9em !important;
        &.flexStart {
            justify-content: space-around;
    
            .resultItem {
                >div {
                    margin: auto .4em;
                }
            }
        }    
    }
}

@media (max-width: 320px) {
    .results {
        font-size: .8em !important;
    }
}