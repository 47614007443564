@import "src/styles/bootstrap5/functions";
@import "src/styles/bootstrap5/variables";
.main {
    display: flex;
    align-items: baseline;
}
.Home {
    
    font-size: .782em;
    overflow: hidden;

    .Tabs {
        padding: 0;
        margin: 0;
        display: flex;
        list-style: none;
        font-size: .9em;

        > li {
            width: 100%;
            text-align: center;
            padding: .9em 0.4em;
            background: $gray-100;
            border: 1px solid $gray-300;
            border-top: none;
            border-left: none;
            cursor: pointer;
            user-select: none;

            &:first-child {
                border-left: none;
            }

            &:last-child {
                border-right: none;
            }

            &:hover {
                background: whitesmoke;   
            }

            &.active {
                background: white;
                border-color: white;
                + li {
                    border-left: 1px solid $gray-300;
                }
            }
        }

        .menuTab {
            border: none;
            background: whitesmoke;
            width: 50px;
            padding: 0.5rem;
        }
    }

    &.Mobile {
        font-size: .9em;

        // Bring the tabs down:
        // .Tabs {
        //     position: fixed;
        //     bottom: 0;
        //     display: flex;
        //     width: 100%;
    
        //     li {
        //         border: 1px solid $gray-300;
        //         border-bottom: none;    
    
        //         &.active {
        //             background: white;
        //             border-color: white;
        //             + li {
        //                 border-left: 1px solid $gray-300;
        //             }
        //         }
        //     }    
        // }
   }
}

@media (max-width: 380px) {
    .Home.Mobile {
        font-size: .8em;
    }
}