@import "src/styles/bootstrap5/functions";
@import "src/styles/bootstrap5/variables";


.innerPriceUpdater {
    position: absolute;
    display: flex;
    right: 5%;
    top: 50%;
    transform: translateY(-50%);
    font-size: .8rem;
    overflow: hidden;
    max-width: 45%;
    text-align: right;

    // &.rtl {
    //     left: 5%;
    //     right: unset;
    // }

    button {
        text-align: center;
        padding: 0;
        line-height: 1;

        &[disabled] {
            color: $gray-700;
        }
    }

    span {
        padding: .5rem .75rem;
    }
}
