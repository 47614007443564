@import "src/styles/bootstrap5/functions";
@import "src/styles/bootstrap5/variables";

.additionalLabel {
    flex: 1;
    display: inline-block;
    margin: 0;
    padding-left: 10px;
    padding-right: 1.7em;
    position: relative;
    cursor: pointer;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: .9em;
    line-height: 1.5em;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    
    .checkmark {
        position: absolute;
        height: .9em;
        width: .9em;
        background-color: $gray-300;
        right: .4em;
        top: 50%;
        transform: translateY(-50%);
        margin: 0;
        cursor: pointer;
        transition: all .2s;

        &:after {
            content: "";
            position: absolute;
            display: none;
        }
    }
    
    &:hover input ~ .checkmark {
        background-color: #ccc;
    }
    
    input:checked ~ .checkmark {
        background-color: var(--primary);
    }
    
    
    input:checked ~ .checkmark:after {
        display: block;
    }
    
    .checkmark:after {
        left: .3em;
        top: .2em;
        width: .3em;
        height: .4em;
        border: solid white;
        border-width: 0 1px 1px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    &.rtl {
        padding-right: 10px;
        padding-left: 1.7em;
        text-align: left;

        .checkmark {
            right: auto;
            left: .4em;
        }
    }
}