@import "src/styles/bootstrap5/functions";
@import "src/styles/bootstrap5/variables";

$bg-color: #FFFFFF;
$font-color: #4FBCA1;

.toggle {
    display: flex;
    font-size: .8rem;
    justify-content: space-between;
    align-items: center;

    input[type="checkbox"] {
        display: none;

        & + .toggleHolder { 
            position: relative;
            color: $font-color;
            display: inline-block;
            width: 30px;
            height: 20px;            
            
            span.bar {
                content: ' ';
                display: block;
                height: 12px;
                width: 30px;
                border-radius: 9px;
                position: absolute;
                border: 1px solid $gray-600;
                background: $gray-300;
                transition: all 0.3s ease-in;
            }
        
            span.pos {
                content: ' ';
                display: block;
                height: 20px;
                width: 20px;
                border-radius: 50%;
                position: absolute;
                top: -4px;
                left: -5px;
                border: 1px solid $gray-600;   
                background: $gray-400;
                transition: all 0.3s ease-in;
            }
        }

        &:checked + .toggleHolder {
            span.bar {
                border: 1px solid var(--darken-primary);   
                background: var(--lighten-primary);
            }

            span.pos {
                border: 1px solid var(--darken-primary);   
                background: var(--primary);
                left: 15px; 
                transition: all 0.3s ease-in;
            }
        }
    }

    &.singleOption {
        justify-content: flex-start;
        input[type="checkbox"] {    
            & + .toggleHolder {
                margin-right: 10px;
            }
        }
    }
  }