#calculator,
#rate-calculator {
    display: flex;
    flex-direction: column;
    padding: .8em;
    border: 1px solid $gray-300;
    border-radius: .5em;
    margin: auto;
    width: var(--calculator-width);
    max-width: 100%;

    &.no-borderline {
        border: none;
    }

    &.wide {
        width: var(--wide-calculator-width);

        .select-box-open {
            .select-box-items {
                max-height: 14em;
                transform: translateY(-2.5em);

                ul {
                    max-height: 11.5em;

                    @media (max-width: 730px) {
                        li {
                            font-size: .9em;
                        }    
                    }
                }
            }    
        }
    }

    &.is-mobile {
        padding-bottom: 2.75rem;
    }

    h3 {
        padding-bottom: 0.35em;
        border-bottom: 1px solid silver;
    }
}

#loading-spinner {
    width: 4em;
    height: 4em;
    position: relative;
    margin: 3.5em auto;
    border: .15em solid $gray-100;
    border-radius: 50%;
    border-top-color: var(--primary);
    animation: spin 1s linear infinite;
}

.btn-link {
    box-shadow: none !important;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
  

.text-align-right {
    text-align: right;
}
.text-align-center {
    text-align: center;
} 
.ltr {
    direction: ltr;
}
.rtl {
    direction: rtl;
}

input {
    direction: ltr;
}

.bold {
    font-weight: bold;
}

#rate-calculator {
    overflow: hidden;
    &.Mobile {
        font-size: .9em;
        padding: 0 2rem;
        width: 100%;
    }
    .inline-box {
        display: inline-block;
        line-height: 3em;
    }
    input[type=text] {
        line-height: 2em;
        padding-left: 5px;
        padding-right: 5px;
        height: 30px;
        text-align: center;
        vertical-align: middle;
    }
    .input-addon {
        width: 15px;
        display: inline-block;
    }
    .result {
        border: 1px solid #dedede;
        border-radius: 4px;
        background: whitesmoke;
        font-weight: bold;
        min-width: 60px;
        height: 30px;
        display: inline-block;
        vertical-align: middle;
        line-height: 1.75em;
        text-align: center;
    }
    .btn {
        padding: 0.3rem 0.75rem;
        line-height: 1.5em;
    }
}

body[dir=rtl] {
    #rate-calculator {
        .inline-box {
            margin-right: auto;
            margin-left: 10px;
        }
    }
}