@import "src/styles/bootstrap5/functions";
@import "src/styles/bootstrap5/variables";


.innerSwitcher {
    position: absolute;
    display: flex;
    right: .6em;
    top: 50%;
    transform: translateY(-50%);
    font-size: .9em;

    label {
        padding: .1em .3em;
        cursor: pointer;
        background: $gray-200;
        text-align: center;
        border: 1px solid transparent;
        user-select: none;

        &:first-child {
            border-radius: .3em 0 0 .3em;
        }

        &:last-child {
            border-radius: 0 .3em .3em 0;
        }

        &:hover {
            background: whitesmoke;
        }
        &.checked {
            background: $gray-300;
            color: var(--darkest-primary);
            border: 1px solid $gray-400;
        }
        @media (max-width: 480px) {
            &.checked {
                display: none;
            }
            &:not(.checked) {
                border-radius: .3em;
            }
        }

    }

    input[type=radio] {
        width: 0;
        height: 0;
        opacity: 0;
        display: none;
    }
}
